@import "styles/vw";

.advantages-sec {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 200px;
    padding-bottom: 200px;
    background-color: var(--bg-secondary);

    .advantages-inner {
        width: 100%;
        max-width: 1240px;
        margin: 0 auto;

        ul {
            display: grid;
            gap: 20px;
            width: 100%;
            grid-template-columns: repeat(3, 1fr);

            li {
                background: #ffffff;
                box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
                border-radius: 16px;
                padding: 20px 0px 76px;
                text-align: center;

                img {
                    width: 100%;
                    height: auto;
                }

                h4 {
                    font-size: 20px;
                    line-height: 120%;
                    text-align: center;
                    margin-bottom: 20px;
                }

                p {
                    text-align: center;
                    font-size: 16px;
                    line-height: 150%;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        padding-left: vw(24);
        padding-right: vw(24);
        padding-top: vw(160);
        padding-bottom: vw(160);

        .advantages-inner {
            ul {
                li {
                    padding: vw(20) 0 vw(76);

                    img {
                        width: vw(200);
                        height: vw(200);
                        object-fit: cover;
                    }

                    h4 {
                        font-size: vw(20);
                        line-height: 120%;
                        margin-bottom: vw(20);
                    }

                    p {
                        font-size: vw(16);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        padding-left: vwmobile(56);
        padding-right: vwmobile(57);
        padding-top: vwmobile(120);
        padding-bottom: vwmobile(120);

        .advantages-inner {
            ul {
                gap: vwmobile(40);
                grid-template-columns: repeat(1, 1fr);

                li {
                    border-radius: 16px;
                    padding: vwmobile(20) vwmobile(31) vwmobile(56);
                    height: vwmobile(416);
                    text-align: center;

                    img {
                        width: vwmobile(200);
                        height: vwmobile(200);
                    }

                    h4 {
                        font-size: vwmobile(20);
                        margin-bottom: vwmobile(20);
                    }

                    p {
                        font-size: vwmobile(16);
                    }
                }
            }
        }
    }
}
