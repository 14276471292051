@import "styles/vw";

.cover-sec {
    height: 100vh;
    padding-top: 96px;
    padding-left: 107px;
    padding-right: 107px;

    .cover-inner {
        display: grid;
        justify-content: space-between;
        align-items: center;
        grid-template-areas:
            "text image"
            "free image";
        max-width: 1240px;
        height: 100%;
        width: 100%;
        margin: 0 auto;

        > div:first-child {
            grid-area: text;
            align-self: flex-end;
            margin-bottom: 56px;

            h2 {
                font-weight: 700;
                font-size: 60px;
                line-height: 120%;
                margin-bottom: 24px;
            }

            p {
                font-size: 20px;
                line-height: 140%;
                color: #929292;

                .sm {
                    display: none;
                }
            }
        }

        .free-tag-container {
            grid-area: free;
            align-self: flex-start;
            width: 107px;
            height: 163px;
        }

        .free-tag {
            position: fixed;
            z-index: 100;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 107px;
            height: 163px;
            background: #ffd600;
            border: 3px solid #000000;
            box-shadow: 0px 1px 3px rgba(48, 48, 48, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.15);
            border-radius: 90px;

            img {
                width: 67px;
                height: 67px;
                margin-bottom: 8px;
            }

            span {
                font-size: 18px;
                line-height: 111%;
                text-align: center;
            }
        }

        .cover-img {
            grid-area: image;
            width: 609px;
            height: 551px;
        }
    }

    @media screen and (max-width: 1400px) {
        height: auto;
        padding-bottom: 140px;

        .cover-inner {
            padding-top: 119px;
            justify-content: space-between;
            align-items: center;
            grid-template-areas:
                "text text"
                "free image";

            .cover-img {
                width: 75%;
                height: auto;
                justify-self: flex-end;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        padding-left: vw(107);
        padding-right: vw(107);
        padding-top: vw(96);
        padding-bottom: vw(140);

        .cover-inner {
            padding-top: vw(119);

            > div:first-child {
                margin-bottom: vw(56);

                h2 {
                    font-size: vw(60);
                    margin-bottom: vw(24);
                }

                p {
                    font-size: vw(20);
                }
            }

            .free-tag {
                width: vw(107);
                height: vw(163);
                border-radius: vw(90);

                img {
                    width: vw(67);
                    height: vw(67);
                    margin-bottom: vw(8);
                }

                span {
                    font-size: vw(18);
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        position: relative;
        min-height: 100vh;
        height: auto;
        padding-top: 56px;
        padding-bottom: 0;

        .cover-inner {
            padding-top: vwmobile(64);
            grid-template-areas:
                "text"
                "image"
                "free";

            > div:first-child {
                margin-bottom: vwmobile(44);

                h2 {
                    font-size: vwmobile(32);
                    line-height: 125%;
                    margin-bottom: vwmobile(24);
                }

                p {
                    font-size: vwmobile(16);
                    line-height: 175%;

                    .sm {
                        display: initial;
                    }
                }
            }

            .free-tag-container {
                position: absolute;
                width: vwmobile(92);
                height: vwmobile(144);
                bottom: 0;
                right: vwmobile(24);
            }

            .free-tag {
                position: initial;
                width: vwmobile(92);
                height: vwmobile(144);
                transform: translateY(50%);
                border-radius: vwmobile(90);
                z-index: initial;

                img {
                    width: vwmobile(48);
                    height: vwmobile(48);
                    margin-bottom: vwmobile(8);
                }

                span {
                    font-size: vwmobile(14);
                    line-height: 143%;
                }
            }

            .cover-img {
                width: vwmobile(336);
                height: vwmobile(303);
                margin-bottom: vwmobile(117);
            }
        }
    }
}
