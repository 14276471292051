@import "styles/vw";

.slogan-sec {
    position: relative;
    padding-bottom: 200px;

    .slogan-inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;

        .line {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            width: 8px;

            &:first-child {
                div {
                    height: 200px;
                }
            }

            div {
                height: 282px;
                width: 2px;
                background: #000000;
            }

            span {
                width: 8px;
                height: 8px;
                border: 2px solid #000000;
                border-radius: 50%;
            }
        }

        p {
            font-size: 52px;
            line-height: 154%;
            text-align: center;

            &.p-1 {
                margin-top: 80px;
                margin-bottom: 80px;
            }

            &.p-2 {
                position: relative;
                font-size: 80px;
                line-height: 100%;
                margin-top: 24px;
                margin-bottom: 56px;
            }

            &.p-3 {
                margin-top: 80px;
            }
        }

        div.fade-in-up {
            width: 100vw;
            > div {
                position: absolute;
                width: calc(2240px * 14);
                height: 80px;
                top: 0;
                left: 0;
                background-position: 0 center;
                background-size: contain;
                transform: translateY(calc(-50% + 40px));
                animation: slide-lg 400s linear infinite;

                &:nth-child(2n) {
                    left: initial;
                    right: 0;
                    animation: slide-lg-right 400s linear infinite;
                }

                &:first-child {
                    top: -164px;
                    background: url(../../assets/slogan_bg1.png) repeat-x;
                }

                &:nth-child(2) {
                    top: -28px;
                    background: url(../../assets/slogan_bg2.png) repeat-x;
                }

                &:nth-child(3) {
                    top: 108px;
                    background: url(../../assets/slogan_bg3.png) repeat-x;
                }

                &:nth-child(4) {
                    top: 244px;
                    background: url(../../assets/slogan_bg4.png) repeat-x;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        padding-bottom: vw(200);

        .slogan-inner {
            .line {
                &:first-child {
                    div {
                        height: vw(200);
                    }
                }

                div {
                    height: vw(282);
                }

                span {
                    width: vw(8);
                    height: vw(8);
                }
            }

            p {
                font-size: vw(52);

                &.p-1 {
                    margin-top: vw(80);
                    margin-bottom: vw(80);
                }

                &.p-2 {
                    font-size: vw(80);
                    margin-top: vw(24);
                    margin-bottom: vw(56);

                    &:after {
                        height: vw(488);
                        width: vw(2240 * 14);
                        transform: translateY(calc(-50% + vw(40)));
                        animation: slide-md 600s linear infinite;
                    }
                }

                &.p-3 {
                    margin-top: vw(80);
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        padding-bottom: vwmobile(120);

        .slogan-inner {
            .line {
                &:first-child {
                    div {
                        height: vwmobile(200);
                    }
                }

                div {
                    height: vwmobile(282);
                }

                span {
                    width: vwmobile(8);
                    height: vwmobile(8);
                }
            }

            p {
                font-size: vwmobile(24);
                line-height: 167%;

                &.p-1 {
                    margin-top: vwmobile(40);
                    margin-bottom: vwmobile(40);
                }

                &.p-2 {
                    font-size: vwmobile(32);
                    margin-top: vwmobile(40);
                    margin-bottom: vwmobile(40);

                    &:after {
                        width: vwmobile(896 * 14);
                        transform: translateY(calc(-50% + vwmobile(20)));
                        animation: slide-sm 300s linear infinite;
                    }
                }

                &.p-3 {
                    margin-top: vwmobile(40);
                }
            }
        }
    }
}

@keyframes slide-lg {
    0% {
        transform: translateX(0) translateY(calc(-50% + 40px));
    }
    100% {
        transform: translateX(calc(-2240px * 7)) translateY(calc(-50% + 40px));
    }
}

@keyframes slide-md {
    0% {
        transform: translateX(0) translateY(calc(-50% + vw(40)));
    }
    100% {
        transform: translateX(vwmobile(-2240 * 7)) translateY(calc(-50% + vw(40)));
    }
}

@keyframes slide-sm {
    0% {
        transform: translateX(0) translateY(calc(-50% + vwmobile(20)));
    }
    100% {
        transform: translateX(vwmobile(-896 * 7)) translateY(calc(-50% + vwmobile(20)));
    }
}

@keyframes slide-lg-right {
    0% {
        transform: translateX(0) translateY(calc(-50% + 40px));
    }
    100% {
        transform: translateX(calc(2240px * 7)) translateY(calc(-50% + 40px));
    }
}

@keyframes slide-md-right {
    0% {
        transform: translateX(0) translateY(calc(-50% + vw(40)));
    }
    100% {
        transform: translateX(vwmobile(2240 * 7)) translateY(calc(-50% + vw(40)));
    }
}

@keyframes slide-sm-right {
    0% {
        transform: translateX(0) translateY(calc(-50% + vwmobile(20)));
    }
    100% {
        transform: translateX(vwmobile(896 * 7)) translateY(calc(-50% + vwmobile(20)));
    }
}
