.checkbox-field {
    display: flex;
    flex-direction: column;

    .checkbox-container {
        position: relative;
        display: inline-flex;
        align-items: center;
        width: 100%;
        cursor: pointer;
        transition: 0.3s;

        .label {
            flex: 1;
            padding-left: 8px;
            font-size: 14px;
            line-height: 14px;
        }

        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        .checkbox {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 24px;

            span {
                height: 18px;
                width: 18px;
                border: 2px solid #ababab;
                border-radius: 1px;
            }
        }
    }

    .error-message {
        display: inline-block;
        color: #e72222;
        padding: 0 10px;
        margin-top: 4px;
        font-size: 12px;
        line-height: 16px;
    }
}
