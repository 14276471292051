@import "styles/vw";

.product-upload-sec {
    height: 704px;
    padding-left: 123px;
    padding-right: 123px;
    padding-top: 120px;
    padding-bottom: 80px;
    background-color: var(--bg-secondary);

    .product-upload-inner {
        max-width: 777px;
        margin: 0 auto;

        h3 {
            font-weight: 500;
            font-size: 40px;
            line-height: 100%;
            text-align: center;
            margin-bottom: 60px;

            br {
                display: none;
            }
        }

        .caption {
            display: flex;
            margin-bottom: 40px;

            > div {
                display: flex;
                font-weight: 500;
                font-size: 14px;
                line-height: 143%;

                span:first-child {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background-color: #d9d9d9;
                    border-radius: 2px;
                    margin-right: 8px;
                    font-size: 14px;
                }

                &:last-child {
                    margin-left: 24px;

                    span:first-child {
                        background-color: #3f3f3f;
                    }
                }
            }
        }

        ul {
            display: flex;
            flex-direction: column;

            li {
                position: relative;
                display: inline-flex;
                align-items: center;
                height: 88px;
                transform-origin: left;

                &:not(:last-child) {
                    margin-bottom: 40px;
                }

                > div {
                    width: 100%;

                    div {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        height: 47px;
                        width: 100%;
                        font-size: 18px;
                        line-height: 111%;
                    }

                    &:first-child {
                        width: 33.7%;
                        flex-shrink: 0;

                        div {
                            background: #dedede;
                            border-radius: 8px 0px 0px 8px;
                            padding-right: 44px;
                            flex-shrink: 0;
                        }
                    }

                    &:last-child {
                        div {
                            background: #3f3f3f;
                            border-radius: 0px 8px 8px 0px;
                            color: #fff;
                            padding-left: 44px;
                        }

                        > img,
                        > span {
                            display: none;
                        }
                    }

                    > img,
                    > span {
                        position: absolute;
                        width: 88px;
                        height: 88px;
                        top: 0;
                        transform: translateX(-50%);
                    }

                    > span {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        background: #ffffff;
                        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                        border-radius: 50%;

                        img {
                            width: 72px;
                            height: 14px;
                        }
                    }
                }

                &:first-child {
                    width: 68.2%;
                }

                &:nth-child(2) {
                    width: 100%;
                }

                &:last-child {
                    width: 46.6%;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        height: vw(704);
        padding-left: vw(123);
        padding-right: vw(123);
        padding-top: vw(120);
        padding-bottom: vw(80);

        .product-upload-inner {
            h3 {
                font-size: vw(40);
                margin-bottom: vw(60);
            }

            .caption {
                margin-bottom: vw(40);

                > div {
                    font-size: vw(14);

                    span:first-child {
                        width: vw(20);
                        height: vw(20);
                        margin-right: vw(8);
                    }

                    &:last-child {
                        margin-left: vw(24);
                    }
                }
            }

            ul {
                li {
                    height: vw(88);

                    &:not(:last-child) {
                        margin-bottom: vw(40);
                    }

                    > div {
                        div {
                            font-size: vw(18);
                            height: vw(47);
                        }

                        > img,
                        > span {
                            width: vw(88);
                            height: vw(88);
                        }

                        > span {
                            img {
                                width: vw(72);
                                height: vw(14);
                            }
                        }

                        &:first-child {
                            div {
                                padding-right: vw(44);
                            }
                        }

                        &:last-child {
                            div {
                                padding-left: vw(44);
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        height: auto;
        padding-left: vwmobile(61);
        padding-right: vwmobile(61);
        padding-top: vwmobile(100);
        padding-bottom: vwmobile(80);

        .product-upload-inner {
            h3 {
                font-size: vwmobile(28);
                line-height: 143%;
                margin-bottom: vwmobile(40);

                br {
                    display: initial;
                }
            }

            .caption {
                margin-bottom: vwmobile(40);

                > div {
                    font-size: vwmobile(14);

                    span:first-child {
                        width: vwmobile(20);
                        height: vwmobile(20);
                        margin-right: vwmobile(8);
                    }

                    &:last-child {
                        margin-left: vwmobile(24);
                    }
                }
            }

            ul {
                li {
                    height: auto;
                    flex-direction: column;
                    align-items: flex-start;

                    &:not(:last-child) {
                        margin-bottom: vwmobile(40);
                    }

                    > div {
                        position: relative;
                        display: inline-flex;
                        align-items: center;
                        height: vwmobile(44);
                        display: grid;
                        grid-template-areas: "img bar";
                        grid-template-columns: auto 1fr;

                        div {
                            font-size: vwmobile(14);
                            line-height: 143%;
                            height: vwmobile(23);
                            grid-area: bar;
                            margin-left: vwmobile(-22);
                            width: calc(100% + vwmobile(22));
                            padding-left: vwmobile(22);
                        }

                        > img,
                        > span {
                            display: inline-flex !important;
                            grid-area: img;
                            position: initial;
                            flex-shrink: 0;
                            width: vwmobile(44);
                            height: vwmobile(44);
                            transform: none;
                        }

                        > span {
                            img {
                                width: vwmobile(35.68);
                                height: vwmobile(6.94);
                            }
                        }

                        &:first-child {
                            width: 64%;
                            margin-bottom: vwmobile(16);

                            div {
                                border-radius: 0px 8px 8px 0px;
                                padding-right: 0;
                            }
                        }

                        &:last-child {
                            div {
                                padding-left: 0;
                            }
                        }
                    }

                    &:first-child {
                        width: 74%;
                    }

                    &:nth-child(2) {
                        width: 100%;
                    }

                    &:last-child {
                        width: 51.5%;
                    }
                }
            }
        }
    }
}
