.join-sec {
    padding-left: 56px;
    padding-right: 56px;
    padding-top: 96px;
    flex: 1;

    @media screen and (max-width: 767px) {
        padding-top: 56px;
    }

    .join-inner {
        padding-top: 80px;
        padding-bottom: 80px;
        max-width: 610px;
        margin: 0 auto;

        h2 {
            text-align: center;
            font-size: 28px;
            line-height: 28px;
            margin-bottom: 10px;
        }

        p {
            text-align: center;
            font-size: 16px;
            line-height: 16px;
            color: #929292;
            margin-bottom: 20px;

            br {
                display: none;
            }

            @media screen and (max-width: 525px) {
                line-height: 20px;

                br {
                    display: initial;
                }
            }
        }

        form {
            display: flex;
            flex-direction: column;

            .form-group {
                &:not(:first-child) {
                    animation: fadeIn 1s;
                }
            }

            > button {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                border-radius: 8px;
                height: 48px;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                background: var(--text);
                color: #fff;
                margin-top: 20px;
                cursor: pointer;

                &:disabled {
                    background: #dedede;
                    color: #787878;
                    cursor: not-allowed;
                }
            }

            > a {
                margin: 20px 0;
                display: inline-flex;
                align-self: center;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #454545;
            }

            .checkbox-field:not(:last-of-type) {
                margin-bottom: 8px;
            }

            .collapse-form {
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                border: 1px solid #c4c4c4;
                border-radius: 8px;
                padding: 0 16px;
                margin-bottom: 20px;

                .ReactCollapse--content {
                    margin-top: 20px;
                }

                .ReactCollapse--collapse {
                    padding-top: 0;
                }

                .collapse-toggle {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px 0;

                    svg {
                        transition: 0.5s;
                    }

                    &.active {
                        svg {
                            transform: rotate(-180deg);
                        }

                        .ReactCollapse--collapse {
                            padding-top: 20px;
                        }
                    }

                    > div {
                        padding-right: 16px;

                        h3 {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 16px;
                            margin-bottom: 8px;
                        }

                        span {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 12px;
                            color: #787878;
                            word-break: keep-all;
                        }
                    }
                }
            }
        }
    }
}
