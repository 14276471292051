.App {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.fade-in-up {
  opacity: 0;
  transform: translateY(70px);
}

.fade-in-left {
  opacity: 0;
  transform: translateX(-70px);
}

.ReactCollapse--collapse {
  transition: height 500ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
