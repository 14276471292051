@import "styles/vw";

.others-services-sec {
  padding-top: 180px;
  padding-bottom: 180px;
  padding-left: 101px;
  padding-right: 102px;
  background-color: var(--bg-secondary);

  .others-services-inner {
    max-width: 951px;
    width: 100%;
    margin: 0 auto;

    h3 {
      font-weight: 500;
      font-size: 40px;
      line-height: 140%;
      text-align: center;
      margin-bottom: 120px;
    }

    ul {
      li {
        display: flex;
        align-items: center;

        img {
          border-radius: 16px;
          width: 400px;
          height: 400px;
        }

        &:nth-child(2n + 1) {
          img {
            margin-right: 125px;
          }
        }

        &:nth-child(2n) {
          justify-content: flex-end;

          img {
            margin-left: 125px;
          }

          .texts-content {
            p {
              text-align: right;
            }
          }
        }

        .texts-content {
          p {
            white-space: nowrap;

            &:first-child {
              font-size: 32px;
              line-height: 150%;
              margin-bottom: 24px;
            }

            &:nth-child(2) {
              font-size: 18px;
              line-height: 167%;
              color: #787878;
            }

            br.sm {
              display: none;
            }
          }

          button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 400px;
            height: 64px;
            background: #673ab7;
            border-radius: 8px;
            font-weight: 500;
            font-size: 24px;
            line-height: 1;
            color: #ffffff;
            margin-top: 46px;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding-top: vw(180);
    padding-bottom: vw(180);
    padding-left: vw(101);
    padding-right: vw(102);

    .others-services-inner {
      h3 {
        font-size: vw(40);
        margin-bottom: vw(120);
      }

      ul {
        li {
          display: flex;
          align-items: center;

          img {
            width: vw(380);
            height: vw(380);
          }

          &:nth-child(2n + 1) {
            img {
              margin-right: vw(60);
            }
          }

          &:nth-child(2n) {
            img {
              margin-left: vw(60);
            }
          }

          .texts-content {
            p {
              &:first-child {
                font-size: vw(32);
                margin-bottom: vw(24);
              }

              &:nth-child(2) {
                font-size: vw(18);
              }
            }

            button {
              width: vw(375);
              height: vw(64);
              font-size: vw(24);
              margin-top: vw(46);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 650px) {
    padding-top: vwmobile(120);
    padding-bottom: vwmobile(120);
    padding-left: vwmobile(24);
    padding-right: vwmobile(24);

    .others-services-inner {
      h3 {
        font-size: vwmobile(28);
        line-height: 143%;
        margin-bottom: vwmobile(80);
      }

      ul {
        li {
          flex-direction: column;

          img {
            width: vwmobile(312);
            height: vwmobile(312);
            margin-bottom: vwmobile(24);
          }

          &:not(:last-child) {
            margin-bottom: vwmobile(80);
          }

          &:nth-child(2n + 1) {
            img {
              margin-right: 0;
            }
          }

          &:nth-child(2n) {
            flex-direction: column-reverse;

            img {
              margin-left: 0;
            }
          }

          .texts-content {
            p {
              text-align: center !important;

              br.sm {
                display: initial;
              }

              &:first-child {
                font-size: vwmobile(24);
                line-height: 133%;
                margin-bottom: vwmobile(24);
              }

              &:nth-child(2) {
                font-size: vwmobile(18);
                line-height: 144%;
              }
            }

            button {
              width: 100%;
              height: vwmobile(64);
              font-size: vwmobile(18);
              line-height: 178%;
              margin-top: vwmobile(80);
            }
          }
        }
      }
    }
  }
}
