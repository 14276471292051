.form-group {
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;

    &.error {
        .form-wrapper {
            border: 1px solid #e72222;
        }
    }

    label {
        padding: 0 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 8px;

        .label {
            flex-shrink: 0;
        }

        .sub-label {
            font-size: 12px;
            line-height: 12px;
            color: #787878;
            margin-left: 4px;
        }
    }

    .form-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        height: 48px;
        border: 1px solid var(--text);
        border-radius: 8px;
    }

    input {
        background-color: transparent;
        border: 0;
        padding: 0 10px;
        outline: 0;
        width: 100%;
        height: 100%;
        font-size: 14px;
        line-height: 16px;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px var(--bg) inset !important;
        -webkit-text-fill-color: var(--text) !important;
    }

    svg {
        margin-right: 10px;
    }

    .error-message {
        display: inline-block;
        color: #e72222;
        padding: 0 10px;
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
    }

    .input-btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        background: #111111;
        border-radius: 4px;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #ffffff;
        padding: 0 16px;
        white-space: nowrap;
        margin-right: 10px;
        cursor: pointer;
    }
}
