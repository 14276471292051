@import "styles/vw";

.header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 96px;
    padding-top: 40px;
    padding-right: 56px;
    padding-left: 32px;
    background-color: var(--header-bg);
    color: #fff;
    z-index: 10;

    .header-left {
        display: flex;

        h1 {
            padding-right: 34px;
        }

        nav {
            ul {
                display: flex;
                padding: 0 10px;

                li {
                    padding: 12px 20px;
                    font-size: 16px;
                    line-height: 100%;

                    a {
                        color: #fff;
                    }

                    &:last-child {
                        display: none;
                    }
                }
            }
        }
    }

    .header-right {
        display: flex;

        button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            padding: 0 24px;
            border: 1px solid #fff;
            color: #fff;
            font-size: 16px;
            line-height: 100%;
            background-color: transparent;
            border-radius: 8px;
            cursor: pointer;

            &:last-child {
                margin-left: 16px;
                background-color: #fff;
                color: var(--text);
            }
        }
    }

    .nav-toggle {
        display: none;
        padding-left: 14px;
        padding-bottom: 15px;
        background-color: transparent;
        border: none;
        outline: 0;
        cursor: pointer;
        z-index: 1;

        i {
            display: block;
            position: relative;
            width: 20px;
            height: 20px;

            div {
                background-color: #fff;
            }

            .top {
                display: block;
                position: absolute;
                height: 2px;
                transform: rotate(0deg) translateZ(0);
                width: 100%;
                top: 3px;
                left: 0;
                opacity: 1;
                margin: auto;
                overflow: hidden;
                border-radius: 2px;
                transition: transform 0.3s ease-in-out 0s, background 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s,
                    top 0.3s ease-in-out 0s, left 0.3s ease-in-out 0s, width 0.3s ease-in-out 0s;
            }

            .middle-1 {
                display: block;
                position: absolute;
                left: 0;
                height: 2px;
                opacity: 1;
                transform: rotate(0deg) translateZ(0);
                width: 100%;
                top: 11px;
                margin: auto;
                overflow: hidden;
                border-radius: 2px;
                transition: transform 0.3s ease-in-out 0s, background 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s,
                    top 0.3s ease-in-out 0s, left 0.3s ease-in-out 0s, width 0.3s ease-in-out 0s;
            }

            .middle-2 {
                display: block;
                position: absolute;
                left: 0;
                height: 2px;
                opacity: 1;
                transform: rotate(0deg) translateZ(0);
                width: 100%;
                top: 11px;
                margin: auto;
                overflow: hidden;
                border-radius: 2px;
                transition: transform 0.3s ease-in-out 0s, background 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s,
                    top 0.3s ease-in-out 0s, left 0.3s ease-in-out 0s, width 0.3s ease-in-out 0s;
            }

            .bottom {
                display: block;
                position: absolute;
                height: 2px;
                transform: rotate(0deg);
                width: 100%;
                top: 19px;
                left: 0;
                opacity: 1;
                margin: auto;
                overflow: hidden;
                border-radius: 2px;
                transition: transform 0.3s ease-in-out 0s, background 0.3s ease-in-out 0s, opacity 0.3s ease-in-out 0s,
                    top 0.3s ease-in-out 0s, left 0.3s ease-in-out 0s, width 0.3s ease-in-out 0s;
            }
        }

        &.active {
            i {
                .top {
                    width: 0;
                    top: 11px;
                    left: 50%;
                    opacity: 0;
                }

                .middle-1 {
                    transform: rotate(-45deg);
                }

                .middle-2 {
                    transform: rotate(45deg);
                }

                .bottom {
                    width: 0;
                    top: 11px;
                    left: 50%;
                    opacity: 0;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        height: vw(96);
        padding-top: vw(40);
        padding-right: vw(56);
        padding-left: vw(32);

        .header-left {
            h1 {
                padding-right: vw(34);
                img {
                    width: vw(144);
                    height: vw(32);
                }
            }

            nav {
                ul {
                    padding: 0 vw(10);

                    li {
                        padding: vw(12) vw(20);
                        font-size: vw(16);
                    }
                }
            }
        }

        .header-right {
            button {
                height: vw(40);
                padding: 0 vw(24);
                font-size: vw(16);

                &:last-child {
                    margin-left: vw(16);
                }
            }
        }
    }

    @media screen and (max-width: 767px) {
        height: 56px;
        padding-top: 15px;
        padding-right: 24px;
        padding-left: 24px;

        .header-left {
            h1 {
                padding-right: 0;
                img {
                    width: 120px;
                    height: 26px;
                }
            }

            nav {
                background-color: var(--header-bg);
                position: fixed;
                width: 100%;
                top: 56px;
                left: 0;
                border-top: 1px solid #454545;
                opacity: 0;
                transform: translateY(-100%);
                transition: 0.3s;

                &.active {
                    transform: translateY(0);
                    opacity: 1;
                }

                ul {
                    padding: 0;
                    flex-direction: column;

                    li {
                        font-size: 16px;
                        line-height: 20px;
                        padding: 20px 24px;

                        &:last-child {
                            display: initial;
                        }
                    }
                }
            }
        }

        .header-right {
            display: none;
        }

        .nav-toggle {
            display: block;
        }
    }
}
