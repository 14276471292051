.reset-password-sec {
  padding-left: 56px;
  padding-right: 56px;
  padding-top: 96px;
  flex: 1;

  @media screen and (max-width: 767px) {
    padding-top: 56px;
  }

  .reset-password-inner {
    padding-top: 100px;
    padding-bottom: 58px;
    max-width: 480px;
    margin: 0 auto;

    h2 {
      text-align: center;
      font-size: 60px;
      line-height: 72px;
      margin-bottom: 10px;
    }

    p {
      text-align: center;
      font-size: 16px;
      line-height: 16px;
      color: #929292;
      margin-bottom: 20px;
    }

    form {
      display: flex;
      flex-direction: column;

      .form-group {
        &:not(:first-child) {
          animation: fadeIn 1s;
        }
      }

      > button {
        margin-top: 40px;
        height: 48px;
        background: var(--text);
        border-radius: 8px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
        cursor: pointer;

        &:disabled {
          background: #dedede;
          color: #787878;
          cursor: not-allowed;
        }
      }
    }
  }
}
