@import "styles/vw";

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100000001;
    display: grid;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow: auto;

    @media screen and (max-width: 650px) {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.newsletter-modal {
    display: inline-flex;
    position: relative;
    background-color: var(--bg);
    padding: 58px 80px 34px;
    width: 640px;
    max-width: 90vw;
    outline: 0;
    transform: translateY(70px);
    opacity: 0;
    transition: 0.3s;
    border-radius: 16px;

    @media screen and (max-width: 650px) {
        border-radius: 0;
        width: 100%;
        max-width: initial;
        padding: 58px vwmobile(24);
    }

    &.ReactModal__Content--after-open {
        transform: translateY(0);
        opacity: 1;
    }

    h3 {
        font-weight: 500;
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 8px;
        text-align: center;
    }

    p {
        font-size: 18px;
        line-height: 18px;
        text-align: center;
        color: #787878;
        margin-bottom: 20px;
    }

    form {
        display: flex;
        flex-direction: column;

        .checkbox-field {
            align-self: center;
        }

        button {
            color: #fff;
            width: 100%;
            height: 48px;
            outline: 0;
            background-color: var(--text);
            cursor: pointer;
            margin-top: 20px;
            padding: 16px 10px;
            border-radius: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 1;
        }
    }

    .close {
        position: absolute;
        display: inline-flex;
        right: 24px;
        top: 24px;
        width: 24px;
        height: 24px;
        font-size: 0;
        margin: 0;
        border: 0;
        outline: 0;
        background-color: transparent;
        cursor: pointer;
    }
    .close:hover {
        opacity: 1;
    }
    .close:before,
    .close:after {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        content: " ";
        height: 32px;
        width: 2px;
        background-color: var(--text);
    }
    .close:before {
        transform: rotate(45deg);
    }
    .close:after {
        transform: rotate(-45deg);
    }
}
