@import "styles/vw";

.process-sec {
    padding-top: 200px;
    padding-bottom: 240px;
    padding-left: 87px;
    padding-right: 85px;

    .process-inner {
        width: 100%;
        max-width: 1029px;
        margin: 0 auto;

        h3 {
            font-weight: 500;
            font-size: 40px;
            line-height: 100%;
            text-align: center;
            margin-bottom: 120px;
        }

        ol {
            display: flex;
            justify-content: space-between;

            li {
                text-align: center;

                span {
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 44px;
                    background: var(--text);
                    border-radius: 22px;
                    color: #fff;
                    margin-bottom: 24px;
                }

                h4 {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 120%;
                    margin-bottom: 16px;
                    text-align: center;
                }

                p {
                    font-size: 16px;
                    line-height: 150%;
                    text-align: center;
                }

                &.separator {
                    margin-top: 74px;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        padding-top: vw(160);
        padding-bottom: vw(160);
        padding-left: vw(87);
        padding-right: vw(85);

        .process-inner {
            h3 {
                font-size: vw(40);
                margin-bottom: vw(120);
            }

            ol {
                li {
                    span {
                        width: vw(32);
                        height: vw(44);
                        border-radius: vw(22);
                        margin-bottom: vw(24);
                    }

                    h4 {
                        font-size: vw(20);
                        margin-bottom: vw(16);
                    }

                    p {
                        font-size: vw(16);
                    }

                    &.separator {
                        margin-top: vw(74);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        padding-top: vwmobile(120);
        padding-bottom: vwmobile(120);
        padding-left: 0;
        padding-right: 0;

        .process-inner {
            h3 {
                font-size: vwmobile(28);
                line-height: 143%;
                margin-bottom: vwmobile(80);
            }

            ol {
                flex-direction: column;

                li {
                    span {
                        width: vwmobile(32);
                        height: vwmobile(44);
                        border-radius: vwmobile(22);
                        margin-bottom: vwmobile(24);
                    }

                    h4 {
                        font-size: vwmobile(20);
                        margin-bottom: vwmobile(16);
                    }

                    p {
                        font-size: vwmobile(16);
                    }

                    &.separator {
                        transform: rotate(90deg);
                        margin-top: vwmobile(56);
                        margin-bottom: vwmobile(56);
                    }
                }
            }
        }
    }
}
