@import-normalize;

:root {
  --header-bg: #111111;
  --footer-bg: #202020;
  --bg: #ffffff;
  --bg-secondary: #f9f9f9;
  --text: #000000;
  --text-secondary: #929292;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  font-family: "Noto Sans KR", sans-serif;
  color: var(--text);
  background-color: var(--bg);
  font-size: 16px;
  font-weight: 400;
}

html,
body,
h1,
h2,
h3,
h4,
a,
p,
span,
strong,
ul,
ol,
li,
div,
section,
main,
nav,
header,
footer,
img,
video,
form,
label,
button {
  margin: 0;
  padding: 0;
  border: 0;
}

ul,
ol {
  list-style: none;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
}

a {
  color: var(--text);
  text-decoration: none;
}

footer,
header,
nav,
section {
  display: block;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

:focus:not(:focus-visible) {
  outline: none;
}
