@import "styles/vw";

.appstores-sec {
    background-image: url(../../assets/store_bg.png);
    background-size: cover;
    padding-left: 24px;
    padding-right: 24px;

    > div {
        max-height: 600px;
        aspect-ratio: 1920/600;
    }

    .appstores-inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        max-width: 1240px;
        width: 100%;
        height: 100%;
        margin: 0 auto;

        > img:first-child {
            width: 328.56px;
            height: 73px;
            margin-bottom: 24px;
        }

        p {
            font-size: 18px;
            line-height: 133%;
            color: #f9f9f9;
            margin-bottom: 56px;
        }

        .appstores {
            display: flex;

            img {
                width: 200px;
                height: 58px;
                filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.3)) drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));

                &:first-child {
                    margin-right: 16px;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        padding-left: vw(24);
        padding-right: vw(24);

        > div {
            aspect-ratio: 976/320;
        }

        .appstores-inner {
            > img:first-child {
                width: vw(200);
                height: vw(44.44);
                margin-bottom: vw(24);
            }

            p {
                font-size: vw(18);
                margin-bottom: vw(56);
            }

            .appstores {
                img {
                    width: vw(200);
                    height: vw(58);

                    &:first-child {
                        margin-right: vw(16);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        background-image: url(../../assets/store_bg_sm.png);
        padding-left: vwmobile(26);
        padding-right: vwmobile(26);
        padding-top: vwmobile(37);
        padding-bottom: vwmobile(37);

        > div {
            aspect-ratio: initial;

            .appstores-inner {
                > img:first-child {
                    width: vwmobile(120);
                    height: vwmobile(26);
                    margin-bottom: vwmobile(16);
                }

                p {
                    font-size: vwmobile(16);
                    line-height: 150%;
                    margin-bottom: vwmobile(24);
                }

                .appstores {
                    flex-direction: column;

                    img {
                        width: vwmobile(200);
                        height: vwmobile(58);

                        &:first-child {
                            margin-right: 0;
                            margin-bottom: vwmobile(16);
                        }
                    }
                }
            }
        }
    }
}
