@import "styles/vw";

.pricing-sec {
  padding-top: 180px;
  padding-bottom: 180px;
  padding-left: 24px;
  padding-right: 24px;

  .pricing-inner {
    max-width: 1240px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pricing-header {
    margin-bottom: 88px;

    h3 {
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      text-align: center;
      margin-bottom: 16px;
    }

    p {
      font-size: 24px;
      line-height: 133%;
      text-align: center;
      color: #787878;
    }
  }

  .vat-note {
    font-size: 14px;
    line-height: 86%;
    color: #787878;
    margin-bottom: 20px;
    align-self: flex-end;
  }

  .pricing-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;

    .pricing-card {
      flex: 1;
      background: #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 16px;
      overflow: hidden;

      .pricing-card-header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        background-color: var(--text);
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: #fff;
      }

      &:first-child {
        .pricing-card-content {
          padding-bottom: 48px;
        }
      }

      &:not(:first-child) {
        .pricing {
          span {
            text-decoration: line-through;
          }
        }
      }

      .pricing-card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 60px;
        padding-top: 40px;

        .pricing {
          display: block;
          text-align: center;
          font-weight: 500;
          font-size: 20px;
          line-height: 133%;
          color: #787878;

          strong {
            font-size: 40px;
            line-height: 175%;
            color: var(--text);
          }
        }

        .pricing-card-vat {
          font-size: 12px;
          line-height: 167%;
          text-align: center;
          color: #787878;
          margin-top: 8px;
        }

        .benefits-list {
          display: inline-flex;
          flex-direction: column;
          margin: 40px auto 0;

          li {
            font-size: 16px;
            line-height: 150%;

            &:not(:last-child) {
              margin-bottom: 8px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding-top: vw(180);
    padding-bottom: vw(180);
    padding-left: vw(24);
    padding-right: vw(24);

    .pricing-header {
      margin-bottom: vw(88);

      h3 {
        font-size: vw(40);
        margin-bottom: vw(16);
      }

      p {
        font-size: vw(24);
      }
    }

    .vat-note {
      font-size: vw(14);
      margin-bottom: vw(20);
    }

    .pricing-list {
      gap: vw(20);

      .pricing-card {
        .pricing-card-header {
          height: vw(72);
          font-size: vw(20);
        }

        &:first-child {
          .pricing-card-content {
            padding-bottom: vw(48);
          }
        }

        .pricing-card-content {
          padding-bottom: vw(60);
          padding-top: vw(40);

          .pricing {
            font-size: vw(20);

            strong {
              font-size: vw(40);
            }
          }

          .pricing-card-vat {
            font-size: vw(14);
            margin-top: vw(8);
          }

          .benefits-list {
            margin: vw(40) auto 0;

            li {
              font-size: vw(16);

              &:not(:last-child) {
                margin-bottom: vw(8);
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 650px) {
    padding-top: vwmobile(120);
    padding-bottom: vwmobile(120);
    padding-left: vwmobile(56);
    padding-right: vwmobile(57);

    .pricing-header {
      margin-bottom: vwmobile(49);

      h3 {
        font-size: vwmobile(28);
        line-height: 114%;
        margin-bottom: vwmobile(8);
      }

      p {
        font-size: vwmobile(18);
        line-height: 100%;
      }
    }

    .vat-note {
      font-size: vwmobile(14);
      margin-bottom: vwmobile(19);
    }

    .pricing-list {
      grid-template-columns: 1fr;
      gap: vwmobile(40);

      .pricing-card {
        .pricing-card-header {
          height: vwmobile(72);
          font-size: vwmobile(20);
        }

        &:first-child {
          .pricing-card-content {
            padding-bottom: vwmobile(60);
          }
        }

        .pricing-card-content {
          padding-bottom: vwmobile(60);
          padding-top: vwmobile(24);

          .pricing {
            font-size: vwmobile(18);

            strong {
              font-size: vwmobile(32);
            }
          }

          .pricing-card-vat {
            font-size: vwmobile(12);
            margin-top: vwmobile(8);
          }

          .benefits-list {
            margin: vw(24) auto 0;

            li {
              font-size: vwmobile(16);

              &:not(:last-child) {
                margin-bottom: vwmobile(8);
              }
            }
          }
        }
      }
    }
  }
}
