@import "styles/vw";

.design-sec {
    background-image: url(../../assets/design_bg.png);
    background-size: cover;
    background-color: var(--bg-secondary);

    .design-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 1300px;
        aspect-ratio: 1920/1300;
        margin: 0 auto;

        .blur {
            padding: 10px;
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(4px);

            h3 {
                background: rgba(255, 255, 255, 0.2);
                backdrop-filter: blur(4px);
                font-size: 52px;
                line-height: 154%;
                text-align: center;
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .design-inner {
            aspect-ratio: 1023/935;

            .blur {
                h3 {
                    font-size: vw(52);
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        .design-inner {
            aspect-ratio: 425/416;

            .blur {
                h3 {
                    font-size: vwmobile(28);
                    line-height: 143%;
                }
            }
        }
    }
}
