@import "styles/vw";

.categories-sec {
    background-image: url(../../assets/categories_bg.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    .categories-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 924px;
        aspect-ratio: 1920/924;
        margin: 0 auto;

        .blur {
            padding: 10px;
            background: rgba(255, 255, 255, 0.2);
            backdrop-filter: blur(4px);

            h3 {
                font-size: 52px;
                line-height: 154%;
                text-align: center;
            }

            p {
                margin-top: 32px;
                font-weight: 500;
                font-size: 24px;
                line-height: 133%;
                text-align: center;

                br {
                    display: none;
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        .categories-inner {
            aspect-ratio: 1023/925;

            .blur {
                h3 {
                    font-size: vw(52);
                }

                p {
                    margin-top: vw(32);
                    font-size: vw(24);
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        .categories-inner {
            aspect-ratio: 425/416;

            .blur {
                h3 {
                    font-size: vwmobile(28);
                    line-height: 143%;
                }

                p {
                    margin-top: vwmobile(16);
                    font-size: vwmobile(20);
                    line-height: 140%;

                    br {
                        display: initial;
                    }
                }
            }
        }
    }
}
