@import "styles/vw";

.customers-sec {
  padding-top: 180px;
  padding-bottom: 180px;
  padding-left: 106px;
  padding-right: 106px;
  background-color: var(--bg-secondary);

  .customers-inner {
    max-width: 1030px;
    width: 100%;
    margin: 0 auto;

    h3 {
      font-weight: 500;
      font-size: 40px;
      line-height: 140%;
      text-align: center;
      margin-bottom: 120px;
    }

    ul {
      li {
        display: inline-flex;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        width: 100%;
        height: 320px;
        padding-left: 80px;

        img {
          width: 240px;
          height: 240px;
          margin-right: 80px;
        }

        p {
          position: relative;
          font-weight: 500;
          font-size: 24px;
          line-height: 167%;
          padding-left: 54px;

          @media screen and (min-width: 651px) {
            br.sm {
              display: none;
            }
          }

          &:before {
            content: "";
            position: absolute;
            width: 30px;
            height: 26px;
            top: -5px;
            left: 0;
            background-image: url(../../assets/quote_up.svg);
            background-size: cover;
          }

          &:after {
            content: "";
            position: absolute;
            width: 30px;
            height: 25px;
            bottom: -5px;
            right: -54px;
            background-image: url(../../assets/quote_down.svg);
            background-size: cover;
          }
        }

        &:not(:last-child) {
          margin-bottom: 32px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding-top: vw(180);
    padding-bottom: vw(180);
    padding-left: vw(106);
    padding-right: vw(106);

    .customers-inner {
      h3 {
        font-size: vw(40);
        margin-bottom: vw(120);
      }

      ul {
        li {
          width: 100%;
          height: vw(320);
          padding-left: vw(40);

          img {
            width: vw(240);
            height: vw(240);
            margin-right: 0;
          }

          p {
            font-size: vw(24);
            padding-left: 0;
            margin: auto;

            &:before {
              width: vw(30);
              height: vw(26);
              top: vw(-25);
            }

            &:after {
              width: vw(30);
              height: vw(25);
              bottom: vw(-25);
              right: 0;
            }
          }

          &:not(:last-child) {
            margin-bottom: vw(32);
          }
        }
      }
    }
  }

  @media screen and (max-width: 650px) {
    padding-top: vwmobile(120);
    padding-bottom: vwmobile(120);
    padding-left: vwmobile(56);
    padding-right: vwmobile(57);

    .customers-inner {
      h3 {
        font-size: vwmobile(28);
        margin-bottom: vw(120);
        line-height: 143%;
      }

      ul {
        li {
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          height: vwmobile(527);
          padding-left: 0;
          padding-top: vwmobile(58);
          padding-bottom: vwmobile(99);

          img {
            width: vwmobile(196);
            height: vwmobile(196);
            margin-right: 0;
          }

          p {
            text-align: center;
            font-size: vwmobile(20);
            line-height: 140%;
            margin: 0 auto;

            br.lg {
              display: none;
            }

            &:before {
              width: vwmobile(30);
              height: vwmobile(26);
              top: vwmobile(-50);
              left: 0;
              right: 0;
              margin: 0 auto;
            }

            &:after {
              width: vwmobile(30);
              height: vwmobile(25);
              bottom: vwmobile(-50);
              left: 0;
              right: 0;
              margin: 0 auto;
            }
          }

          &:not(:last-child) {
            margin-bottom: vw(40);
          }
        }
      }
    }
  }
}
