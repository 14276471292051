.login-sec {
    padding-left: 56px;
    padding-right: 56px;
    padding-top: 96px;
    flex: 1;

    @media screen and (max-width: 767px) {
        padding-top: 56px;
    }

    .login-inner {
        padding-top: 80px;
        padding-bottom: 80px;
        max-width: 480px;
        margin: 0 auto;

        h2 {
            text-align: center;
            font-size: 60px;
            line-height: 72px;
            margin-bottom: 10px;
        }

        p {
            text-align: center;
            font-size: 16px;
            line-height: 30px;
            color: #929292;
            margin-bottom: 20px;
        }

        form {
            display: flex;
            flex-direction: column;

            .form-actions {
                display: flex;
                margin-top: 40px;
                margin-bottom: 20px;

                button {
                    height: 48px;
                    flex: 1;
                    background: var(--text);
                    border-radius: 8px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;
                    color: #ffffff;
                    cursor: pointer;

                    &:last-child {
                        margin-left: 8px;
                        color: var(--text);
                        background: transparent;
                        border: 1px solid var(--text);
                    }
                }
            }

            .reset-password {
                margin: 20px 0;
                display: inline-flex;
                align-self: center;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: #454545;
            }
        }
    }
}
