@import "styles/vw";

.footer {
    background-color: var(--header-bg);
    padding: 40px 24px;
    font-family: "Inter";
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;

    .footer-inner {
        display: flex;
        max-width: 1240px;
        margin: 0 auto;

        img {
            width: 120px;
            height: 26px;
            margin-right: 90px;
        }

        > div {
            p {
                margin-bottom: 20px;

                br.sm {
                    display: none;
                }

                @media screen and (min-width: 1201px) {
                    span {
                        display: none;
                    }
                }
            }

            ul {
                display: flex;

                li {
                    padding-right: 20px;
                    margin-right: 20px;
                    border-right: 1px solid #787878;

                    a {
                        color: #fff;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1200px) {
        padding: vw(40) vw(24);
        font-size: vw(14);
        line-height: 143%;

        .footer-inner {
            img {
                width: vw(120);
                height: vw(26);
                margin-right: vw(90);
            }

            > div {
                p {
                    margin-bottom: vw(20);

                    br {
                        display: none;
                    }
                }

                ul {
                    li {
                        padding-right: vw(20);
                        margin-right: vw(20);
                    }
                }
            }
        }
    }

    @media screen and (max-width: 650px) {
        padding: vwmobile(40) vwmobile(24) vwmobile(60);
        font-size: vwmobile(14);

        .footer-inner {
            flex-direction: column;

            img {
                width: vwmobile(120);
                height: vwmobile(26);
                margin-right: 0;
                margin-bottom: vwmobile(33);
            }

            > div {
                p {
                    margin-bottom: vwmobile(20);

                    br {
                        display: initial !important;
                    }
                }

                ul {
                    li {
                        padding-right: vwmobile(20);
                        margin-right: vwmobile(20);

                        &:last-child {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}
